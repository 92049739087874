import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-input-comment',
  styleUrl: 'x-input-comment.sass',
})
export class XInputComment {
  @Prop() value: string = '';
  @Prop() placeholder: string = '';
  @Prop() disabled: boolean = false;

  render() {
    return (
      <Host>
        <input type="text" value={this.value} placeholder={this.placeholder} disabled={this.disabled} />
        <x-icon-button color="dark-grey">
          <x-icon glyph="send" size="20"></x-icon>
        </x-icon-button>
      </Host>
    );
  }
}
