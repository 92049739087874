x-input-comment
  display: flex
  border-style: solid
  border-color: var(--divider-dark-grey)
  border-width: 1px 0
  padding-right: 24px
  input
    +preset(3)
    +placeholder(var(--on-input-dark-50))
    flex: 1 1 auto
    height: 42px
    padding: 0 24px
    background: none
    border: none
    outline: none
    color: var(--on-input-white-20)
